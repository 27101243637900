<template>
  <div class="product-checkout mt-0">
    <!--    <b-alert-->
    <!--      variant="secondary"-->
    <!--      :show="orderData.id"-->
    <!--    >-->
    <!--      <h4 class="alert-heading">-->
    <!--        Error fetching user data-->
    <!--      </h4>-->
    <!--      <div class="alert-body">-->
    <!--        No user found with this user id. Check-->
    <!--        <b-link-->
    <!--          class="alert-link"-->
    <!--          :to="{ name: 'apps-users-list'}"-->
    <!--        >-->
    <!--          User List-->
    <!--        </b-link>-->
    <!--        for other users.-->
    <!--      </div>-->
    <!--    </b-alert>-->

    <order-details-loader
      v-if="isLoading"
    />
    <validation-observer
      v-else
      ref="refFormObserver"
    >
      <b-form @submit.prevent>
        <b-row>
          <b-col
            cols="12"
            lg="8"
          >
            <order-info-card
              v-if="orderData && orderData.id"
              :item-data="orderData"
              :status-update-in-progress="isUpdateOrderStatusInProgress"
              :is-courier-controls-shipping="orderData.is_courier_controls_shipping"
              :can-create-waybill="orderData.can_create_waybill"
              @status-update-requested="status => onClickUpdateOrderStatus(status)"
            />
            <b-card-group
              deck
              class="mb-0"
            >
              <OrderCustomerCard
                v-if="!orderData.id || orderData.is_editable"
                :options="customerOptions"
                :selected.sync="orderData.customer"
                :errors="validationErrors.customer_id"
                @update:selected="val => loadAddressesOfCustomer(val)"
              />
              <OrderAddressCard
                v-if="!orderData.id || orderData.is_editable"
                :options="addressOptions"
                :selected.sync="orderData.shipping_address"
                :is-active="!!orderData.customer.id"
                :errors="validationErrors.address_id"
                @submit="loadAddressesOfCustomer(orderData.customer)"
                @update:selected="val => loadShippingMethodsOfAddress(val)"
              />
            </b-card-group>

            <section>
              <!-- Shipments List -->
              <shipments-card
                v-if="orderData.id"
                ref="shipmentsCard"
                :order-id="orderData.id"
              />

              <!-- Products List -->
              <order-items
                v-if="!orderData.id || orderData.is_editable || !orderData.shipments.length"
                :options="productOptions"
                :products.sync="orderData.items"
                :readonly="orderData.id && !orderData.is_editable"
                :can-create-shipment="orderData.can_create_shipment"
                @click-create-waybill="onClickUpdateOrderStatus('RDYS')"
                @click-mark-as-shipped="onClickUpdateOrderStatus('SHPD')"
              />
            </section>

            <activity-log-list
              v-if="orderData.id"
              subject-type="order"
              :subject-id="orderData.id"
            />
          </b-col>
          <b-col
            cols="12"
            lg="4"
          >

            <!-- Checkout Options -->
            <div class="checkout-options">
              <OrderShippingCard
                :options="shippingMethodOptions"
                :selected.sync="orderData.shipping_method"
                :errors="validationErrors.shipping_method_code"
                :readonly="orderData.id && !orderData.is_editable"
                :is-active="!!(orderData.shipping_address.id || orderData.shipping_address.address_id)"
                :shipments="orderData.shipments"
              />

              <OrderPaymentCard
                :options="paymentMethodOptions"
                :selected.sync="orderData.payment_method"
                :errors="validationErrors.payment_method_code"
                :readonly="orderData.id && !orderData.is_editable"
                :paid-at="orderData.paid_at"
                :show-status="!!orderData.id"
                :status-update-in-progress="isUpdatePaymentStatusInProgress"
                :is-active="!!orderData.shipping_method.code"
                :payments-data="orderData.paymentsData"
                @status-update-requested="status => updatePaymentStatus(status)"
              />
              <b-card>
                <label
                  v-t="`modules.orders._create.price_details`"
                  class="section-label mb-1"
                />
                <!--        <b-input-group class="input-group-merge coupons">-->
                <!--          <b-form-input placeholder="Coupons" />-->
                <!--          <b-input-group-append is-text>-->
                <!--            <span-->
                <!--              id="input-coupons"-->
                <!--              class="input-group-text text-primary cursor-pointer"-->
                <!--            >Apply</span>-->
                <!--          </b-input-group-append>-->
                <!--        </b-input-group>-->
                <!--        <hr>-->
                <div class="price-details">
                  <!--              <h6 class="price-title">-->
                  <!--                Price Details-->
                  <!--              </h6>-->
                  <ul class="list-unstyled">
                    <li class="price-detail">
                      <div
                        v-t="`modules.orders._create.subtotal`"
                        class="detail-title"
                      />
                      <div class="detail-amt">
                        {{ $n(subtotal, 'currency', 'en-US') }}
                      </div>
                    </li>
                    <li class="price-detail">
                      <div
                        class="detail-title"
                      >{{ $t(`modules.orders._create.vat`) }} ({{ orderData.vat_rate }}%)</div>
                      <div class="detail-amt">
                        {{ $n(vat, 'currency', 'en-US') }}
                      </div>
                    </li>
                    <li class="price-detail">
                      <div
                        v-t="`modules.orders._create.shipping_fees`"

                        class="detail-title"
                      />
                      <div
                        :class="{
                          'detail-amt': true,
                          'discount-amt': true,
                          'text-success': !shippingFees
                        }"
                      >
                        {{ !shippingFees ? $t('Free') : $n(shippingFees, 'currency', 'en-US') }}
                      </div>
                    </li>
                    <li class="price-detail">
                      <div
                        v-t="`modules.orders._create.payment_fees`"
                        class="detail-title"
                      />
                      <div
                        :class="{
                          'detail-amt': true,
                          'discount-amt': true,
                          'text-success': !paymentFees
                        }"
                      >
                        {{ !paymentFees ? $t('Free') : $n(paymentFees, 'currency', 'en-US') }}
                      </div>
                    </li>
                    <!--                <li class="price-detail">-->
                    <!--                  <div class="detail-title">-->
                    <!--                    Discount-->
                    <!--                  </div>-->
                    <!--                  <a-->
                    <!--                    href="javascript:void(0)"-->
                    <!--                    class="detail-amt text-primary"-->
                    <!--                  >Details</a>-->
                    <!--                </li>-->
                    <!--                <li class="price-detail">-->
                    <!--                  <div class="detail-title">-->
                    <!--                    Delivery Charges-->
                    <!--                  </div>-->
                    <!--                  <div class="detail-amt discount-amt text-success">-->
                    <!--                    Free-->
                    <!--                  </div>-->
                    <!--                </li>-->
                  </ul>
                  <hr>
                  <ul class="list-unstyled">
                    <li class="price-detail">
                      <div
                        v-t="`modules.orders._create.total`"
                        class="detail-title detail-total"
                      />
                      <div class="detail-amt font-weight-bolder">
                        {{ $n(total, 'currency', 'en-US') }}
                      </div>
                    </li>
                    <li
                      v-if="orderData && orderData.total_in_customer_currency"
                      class="price-detail"
                    >
                      <div
                        v-t="`modules.orders._create.total_in_customer_currency`"
                        class="detail-title"
                      />
                      <div class="detail-amt">
                        {{ priceText(orderData.total_in_customer_currency) }}
                      </div>
                    </li>
                  </ul>
                  <b-button
                    v-if="(!orderData.id) || orderData.is_editable"
                    v-t="orderData.id?`Save`:`Add`"
                    variant="primary"
                    block
                    :disabled="!subtotal"
                    @click="save"
                  />
                  <b-button
                    v-t="`Exit`"
                    variant="light"
                    block
                    :to="{name: 'orders.list'}"
                  />
                </div>

              </b-card>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <!-- modal create waybill -->
    <wizard-modal-create-waybill
      :order-data="orderData"
      :item-id="orderData.id"
      :currency-code="orderData.currency_code"
      :is-visible="isWaybillModalVisible"
      @update:is-visible="v => isWaybillModalVisible = v"
      @hidden="isWaybillModalVisible = false"
      @submitted="fetchOrderAndShipments"
    />

    <!-- modal add shipment -->
    <modal-add-shipment
      :order-data="orderData"
      :is-visible="isShipmentModalVisible"
      @update:is-visible="v => isShipmentModalVisible = v"
      @hidden="isShipmentModalVisible = false"
      @submitted="fetchOrder"
    />
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BRow,
  BCol,
  BCardGroup, BForm,
} from 'bootstrap-vue'

import {
  computed, onUnmounted, ref,
} from 'vue'
import OrderShippingCard from '@/views/models/orders/add/OrderShippingCard.vue'
import OrderPaymentCard from '@/views/models/orders/add/OrderPaymentCard.vue'
import OrderCustomerCard from '@/views/models/orders/add/OrderCustomerCard.vue'
import OrderAddressCard from '@/views/models/orders/add/OrderAddressCard.vue'
import store from '@/store'
import customerStoreModule from '@/views/models/customers/customerStoreModule'
import orderStoreModule from '@/views/models/orders/orderStoreModule'
import useModelUpdate from '@/views/models/common/useModelUpdate'
import { ValidationObserver } from 'vee-validate'
import router from '@/router'
import OrderInfoCard from '@/views/models/orders/add/OrderInfoCard.vue'
import { handleBadRequestError, toast } from '@core/utils/utils'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import OrderDetailsLoader from '@/views/loaders/OrderDetailsLoader.vue'
import ModalAddShipment from '@/views/models/orders/add/ModalAddShipment.vue'
import ActivityLogList from '@/views/models/activity_logs/ActivityLogList.vue'
import WizardModalCreateWaybill from '@/views/models/waybills/add/WizardModalCreateWaybill.vue'
import ShipmentsCard from '@/views/models/orders/add/ShipmentsCard.vue'
import OrderItems from './OrderItems.vue'
import {priceText} from "../../../../@core/utils/filter";

export default {
  methods: {priceText},
  components: {
    ShipmentsCard,
    WizardModalCreateWaybill,
    ActivityLogList,
    ModalAddShipment,
    OrderDetailsLoader,
    OrderInfoCard,
    ValidationObserver,

    // BSV
    BButton,
    BRow,
    BCol,
    BCard,
    BCardGroup,
    BForm,

    // SFC
    OrderItems,
    OrderAddressCard,
    OrderCustomerCard,
    OrderPaymentCard,
    OrderShippingCard,

  },
  mounted() {
    // Set value of refs
    this.refs.value = this.$refs
  },
  setup() {
    const { t } = useI18nUtils()
    // ? This is just dummy details
    const orderData = ref({
      id: router.currentRoute.params.id || null,
      uuid: null,
      items: [],
      customer: {},
      shipping_address: {
        id: null,
        name: null,
        line_1: null,
        line_2: null,
        mobile: null,
        city: {

        },
        country: {

        },
        description: null,
      },
      shipping_method_code: 'PICKUP',
      payment_method_code: 'COD',
      shipping_method: {

      },
      payment_method: {

      },
      shippingFees: null,
      paymentFees: null,
      vat_rate: store.state.storeConfig?.data?.vat || 0,
      allowed_status_options: [],
      status_options_requires_waybill: [],
      status_options_requires_shipment: [],
      can_create_shipment: false,
      can_create_waybill: false,
      is_courier_controls_shipping: false,
      paymentsData: null,
    })

    const isLoading = computed(() => (orderData.value.id && !orderData.value.uuid))
    const subtotal = computed(() => (orderData.value.items.length ? orderData.value.items.map(item => (parseFloat(String(item.price).replace(',', '')) || 0) * item.selected_qty).reduce((prev, next) => prev + next) : 0))
    const vat = computed(() => (subtotal.value * (orderData.value.vat_rate / 100)))
    const shippingFees = computed(() => ((orderData.value.shipping_method?.charges?.amount || orderData.value.shippingFees?.amount || 0) / 100))
    const paymentFees = computed(() => (orderData.value.paymentFees ? orderData.value.paymentFees.amount : (orderData.value.payment_method?.charges?.amount || 0)) / 100)
    const total = computed(() => (subtotal.value + vat.value + (shippingFees.value || 0) + (paymentFees.value || 0)))
    const customerOptions = ref([])
    const shippingMethodOptions = ref([])
    const paymentMethodOptions = ref([])
    const addressOptions = ref([])
    const productOptions = ref([])
    const isWaybillModalVisible = ref(false)
    const isShipmentModalVisible = ref(false)
    const refs = ref({})
    const shipmentsCard = ref(null)

    const ORDERS_STORE_MODULE_NAME = 'orders'
    const CUSTOMER_STORE_MODULE_NAME = 'customers'

    // Register module
    if (!store.hasModule(ORDERS_STORE_MODULE_NAME)) store.registerModule(ORDERS_STORE_MODULE_NAME, orderStoreModule)
    if (!store.hasModule(CUSTOMER_STORE_MODULE_NAME)) store.registerModule(CUSTOMER_STORE_MODULE_NAME, customerStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ORDERS_STORE_MODULE_NAME)) store.unregisterModule(ORDERS_STORE_MODULE_NAME)
      if (store.hasModule(CUSTOMER_STORE_MODULE_NAME)) store.unregisterModule(CUSTOMER_STORE_MODULE_NAME)
    })

    const {
      validationErrors,
      fetch,
      save,
    } = useModelUpdate(
      ORDERS_STORE_MODULE_NAME,
      refs,
      orderData,
      data => {

      },
      'orders.edit',
      orderData.value.id ? null : 'orders.list',
    )

    const fetchOrder = () => {
      fetch().then(response => {
        if (response) {
          shippingMethodOptions.value = response.options.shipping_methods
          paymentMethodOptions.value = response.options.payment_methods
          customerOptions.value = response.options.customers
          productOptions.value = response.options.products
          addressOptions.value = response.options.addresses

          orderData.value.shippingFees = response.shipping_fees
          orderData.value.paymentFees = response.payment_fees
          orderData.value.paymentsData = response.paymentsData
        }
      })
    }

    const loadOptions = (customerId = null, addressId = null) => {
      store.dispatch(`${ORDERS_STORE_MODULE_NAME}/options`, { customer: customerId, address: addressId })
        .then(response => {
          shippingMethodOptions.value = response.data.data.shipping_methods
          paymentMethodOptions.value = response.data.data.payment_methods
          customerOptions.value = response.data.data.customers
          productOptions.value = response.data.data.products
        })
    }
    if (orderData.value.id) {
      fetchOrder()
    } else {
      loadOptions(null)
    }

    const loadAddressesOfCustomer = customer => {
      orderData.value.shipping_address.id = null
      orderData.value.shipping_address.address_id = null
      orderData.value.shipping_address.description = null
      if (!customer?.id) {
        addressOptions.value = []
      } else {
        store.dispatch(`${CUSTOMER_STORE_MODULE_NAME}/fetchAddresses`, customer.id)
          .then(response => {
            addressOptions.value = response.data.data
          })
      }
    }

    const loadShippingMethodsOfAddress = address => {
      if (!address?.id) {
        shippingMethodOptions.value = []
      } else {
        loadOptions(orderData.value.customer?.id, address.id)
      }
    }

    const isUpdateOrderStatusInProgress = ref(false)
    const updateOrderStatus = status => {
      isUpdateOrderStatusInProgress.value = true
      store.dispatch(`${ORDERS_STORE_MODULE_NAME}/updateOrderStatus`, { orderId: orderData.value.id, status })
        .then(response => {
          toast('success', t('message.operation_success'))
          orderData.value = {
            ...orderData.value,
            ...response,
          }
        })
        .catch(e => {
          if (!handleBadRequestError(e, t)) {
            throw e
          }
        })
        .finally(() => {
          isUpdateOrderStatusInProgress.value = false
        })
    }
    const onClickUpdateOrderStatus = status => {
      if (orderData.value.status_options_requires_waybill.includes(status)) {
        if (!orderData.value.items.length) {
          toast('danger', t('modules.waybills._create._messages.no_unshipped_items'))
          return
        }
        isWaybillModalVisible.value = true
        return
      }

      if (orderData.value.status_options_requires_shipment.includes(status)) {
        isShipmentModalVisible.value = true
        return
      }

      updateOrderStatus(status)
    }

    const isUpdatePaymentStatusInProgress = ref(false)
    const updatePaymentStatus = status => {
      isUpdatePaymentStatusInProgress.value = true
      store.dispatch(`${ORDERS_STORE_MODULE_NAME}/updatePaymentStatus`, { orderId: orderData.value.id, status })
        .then(response => {
          toast('success', t('message.operation_success'))
          orderData.value = {
            ...orderData.value,
            ...response,
          }
        })
        .finally(() => {
          isUpdatePaymentStatusInProgress.value = false
        })
    }

    const fetchOrderAndShipments = () => {
      fetchOrder()
      shipmentsCard.value.fetchItems()
    }

    return {
      isLoading,
      orderData,
      subtotal,
      vat,
      shippingFees,
      paymentFees,
      total,

      customerOptions,
      addressOptions,
      shippingMethodOptions,
      paymentMethodOptions,
      productOptions,

      isUpdateOrderStatusInProgress,
      isUpdatePaymentStatusInProgress,

      isWaybillModalVisible,
      isShipmentModalVisible,

      loadAddressesOfCustomer,
      loadShippingMethodsOfAddress,
      save,
      fetchItemData: fetch, // TODO: Remove if not used
      updateOrderStatus,
      onClickUpdateOrderStatus,
      updatePaymentStatus,
      fetchOrder,
      fetchOrderAndShipments,

      refs,
      shipmentsCard,
      validationErrors,
    }
  },
}
</script>

<!--// TODO: Fix issue: on-edit, the button is disabled -->
<!-- TODO: Prepare single request for order options -->

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce.scss';
[dir] .ecommerce-application .ecommerce-card:hover {
  transform: unset;
}
</style>

<style lang="scss" scoped>

</style>
